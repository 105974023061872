























































































































import Vue from 'vue'

import { ItemAvailabilitiesApiFactory, ItemAvailability } from '@/api-client'

import Zondicon from 'vue-zondicons'
import Switches from 'vue-switches'
import Modal from '@/components/Modal.vue'

const api = ItemAvailabilitiesApiFactory(undefined, '')

type ItemAvailabilityAugmented = ItemAvailability & {
  /** If set, means all providers/brands agree on the state. */
  commonState: boolean | null
  /** The list of brand names for the item */
  brandNames: string[]
}

type DisplayedItemAvailability = ItemAvailabilityAugmented & {
  newState: boolean | null
  displayState: boolean
}

interface DataT {
  /** When True, `fetch` is running */
  isLoading: boolean
  itemsAvailabilities: ItemAvailabilityAugmented[]
  busy: boolean
  /** Stores Item IDs, and their next pending state to send to the server. */
  pendingStates: Record<string, boolean>
  filters: {
    name: string
  }
  error: string
}

export default Vue.extend({
  components: {
    Zondicon,
    Switches,
    Modal,
  },

  data: (): DataT => ({
    isLoading: false,
    busy: false,
    itemsAvailabilities: [],
    pendingStates: {},
    filters: {
      name: '',
    },
    error: '',
  }),

  computed: {
    kitchenID(): string | null {
      return this.$accessor.settings.lastSelectedKitchen
    },
    itemMap(): Record<string, ItemAvailabilityAugmented> {
      return Object.fromEntries(this.itemsAvailabilities.map((i) => [i._id, i]))
    },
    hasPending(): boolean {
      return Object.keys(this.pendingStates).length > 0
    },
    itemsToDisplay(): DisplayedItemAvailability[] {
      return this.itemsAvailabilities.map((itemAvailability) => {
        const newState = this.pendingStates[itemAvailability._id] ?? null
        return {
          ...itemAvailability,
          newState,
          displayState: newState ?? itemAvailability.commonState,
        }
      })
    },
    itemsAvailabilitiesFiltered(): DisplayedItemAvailability[] {
      const name = this.filters.name.toLocaleLowerCase()

      return this.itemsToDisplay.filter((entity) => {
        return (
          name.length === 0 ||
          entity.operational_title.toLocaleLowerCase().search(name) !== -1
        )
      })
    },
  },

  mounted() {
    this.$Progress.start()
    this.fetch()
  },

  methods: {
    clearPending() {
      this.pendingStates = {}
    },
    toggleAvailability(itemID: string) {
      this.$set(
        this.pendingStates,
        itemID,
        itemID in this.pendingStates
          ? !this.pendingStates[itemID]
          : !this.itemMap[itemID].commonState
      )
    },
    async submit() {
      const kitchenID = this.kitchenID
      if (!kitchenID || this.isLoading || this.busy) return
      this.busy = true

      const newlyAvailable = Object.entries(this.pendingStates)
        .filter((pair) => pair[1] === true)
        .map((pair) => pair[0])
      const newlyUnavailable = Object.entries(this.pendingStates)
        .filter((pair) => pair[1] === false)
        .map((pair) => pair[0])

      try {
        const res =
          await api.changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
            kitchenID,
            {
              make_available: newlyAvailable,
              make_unavailable: newlyUnavailable,
            }
          )
        if (res.status === 204) {
          const fetchPromise = this.fetch()
          this.clearPending()
          await fetchPromise
        } else {
          if (res.status === 206)
            this.error =
              'Les plateformes n’ont pas confirmé les nouvelles indisponibilités.'
        }
      } catch (err) {
        if (err.response?.status === 425 || err.response?.status === 429) {
          this.error =
            'Les plateformes refusent les mises à jour trop fréquentes. ' +
            'Veuillez patienter au moins une minute avant de soumettre ' +
            'vos nouvelles (in)disponibilités.'
        } else if (err.response?.status === 503) {
          this.error =
            'Certains restaurants n’ont pas pu confirmer la mise à jour ' +
            'des disponibilités. Elle a tout de même été appliquée pour les autres ' +
            'restaurants. Les disponibilités mises à jour partiellement sont ' +
            'indiquées par la mention “Conflit”.'
        } else {
          this.error =
            'Une erreur inconnue est survenue. ' +
            'Veuillez réessayer ultérieurement.'
          this.$sentry.captureException(err)
        }
      } finally {
        this.busy = false
      }
    },
    async fetch() {
      if (!this.kitchenID || this.isLoading) return
      this.$Progress.start()
      this.isLoading = true
      try {
        const itemsAvailabilitiesRaw = (
          await api.getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
            this.kitchenID
          )
        ).data
          // Sort by name
          .sort((i1, i2) =>
            i1.operational_title.localeCompare(i2.operational_title)
          )

        this.itemsAvailabilities = itemsAvailabilitiesRaw
          // Augment items by computing extra attributes
          .map((item) => {
            return {
              ...item,
              commonState: item.availability.reduce(
                (accumulator, current, idx) =>
                  idx === 0 ||
                  (accumulator !== null && accumulator === current.available)
                    ? current.available ?? null
                    : null,
                null as boolean | null
              ),
              brandNames: [
                ...new Set(item.availability.map((b) => b.brand_short_name)),
              ],
            }
          })
        this.$Progress.finish()
      } catch {
        this.$Progress.fail()
      } finally {
        this.isLoading = false
      }
    },

    // Note: used by vue-closable in handler
    // eslint-disable-next-line vue/no-unused-properties
    blurOnOutsideClick() {
      if (this.$refs.searchInput) {
        ;(this.$refs.searchInput as HTMLInputElement).blur()
      }
    },
  },
})
